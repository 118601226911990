import React from 'react';
import { Link } from 'react-router-dom';

const Footer = ({pageName}) => {

  return (
    <footer className="bg-gray-800 text-white p-4">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        <div>
          <h3 className="text-xl font-bold">GROUPTEN</h3>
          <p>© 2024 GROUPTEN. All rights reserved.</p>
        </div>
        <div>
          <h3 className="text-xl font-bold">Links</h3>
          <ul>
          <li><Link to="/" className={`hover:text-yellow-400`}>Home</Link></li>
          <li><Link to="/about" className={`hover:text-yellow-400 `}>About us</Link></li>
          <li><Link to="/contact" className={`hover:text-yellow-400 `}>Contact us</Link></li>

            {/* <li><a href="#" className="hover:text-gray-400">Terms & Conditions</a></li>
            <li><a href="#" className="hover:text-gray-400">Privacy Policy</a></li> */}
          </ul>
        </div>
        <div>
          <h3 className="text-xl font-bold">Contact Us</h3>
          <p>grouptenbusiness@gmail.com</p>
          <p>+91 94465 66663</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
