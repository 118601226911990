import React, { useState } from 'react';
import ScrollAnimation from '../ScrollAnimation';
import Navbar from './Navbar';
import { ImageUrl } from '../api/common';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Alert from './Alert';
import Lottie from "lottie-react";
import groovyWalkAnimation from "../assets/Animation/groovyWalk.json";

const Hero = ({ title1, title2, title3, p1, is_book_now_btn, banners, alert, setAlert , mainLoading}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false
  };

  return (
    <section className="relative bg-cover bg-center h-screen overflow-hidden w-full">
      {mainLoading ? 
      <Lottie animationData={groovyWalkAnimation} loop={true} className='h-screen'/>
        :
        <div>
       <div className="absolute inset-0 bg-black opacity-60"></div>
      <div className="relative container flex flex-col items-start justify-center h-full text-center text-white w-full">
        <Navbar />
        {alert?.is_show == 1 &&
        <Alert alert={alert} setAlert={setAlert}/>
      }
        <Slider {...settings} className="w-[100vw]">
          {banners.map((banner, index) => (
            <div key={index} className="relative">
              <img src={`${ImageUrl()}${banner.image}`} alt={`Banner ${index + 1}`} className="w-full h-screen object-cover" />
              <div className="absolute inset-0 bg-black opacity-40"></div>
              <div className="absolute inset-0 flex flex-col items-center justify-center">
                <ScrollAnimation className="text-left w-10/12 text-[#ffffff]">
                  <h1 className="text-3xl md:text-4xl lg:text-2xl font-semibold">{banner.title1.toUpperCase()}</h1>
                  <h2 className="text-2xl md:text-3xl lg:text-6xl font-semibold mt-2">{banner.title2.toUpperCase()}</h2>
                  <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold mt-2">{banner.title3.toUpperCase()}</h2>
                  <p className="mt-6 text-lg md:text-xl lg:text-2xl max-w-xl">{banner.description}</p>
                </ScrollAnimation>
                {is_book_now_btn &&
                  <div className="mt-8">
                    <button className="px-6 py-2 bg-yellow-500 text-black font-bold rounded hover:bg-yellow-600 hover:text-white">BOOK NOW</button>
                  </div>
                }
              </div>
            </div>
          ))}
        </Slider>
      </div>
      </div>}
    </section>
  );
};

export default Hero;
