import React, { useEffect, useState } from 'react';
import logo from '../assets/Home/logo.png';
import Navbar from '../components/Navbar';
import welcome from '../assets/AboutUs/welcome1.jpg';
import { getAboutSection2, getAboutSection3 } from '../api/api';
import { ImageUrl } from '../api/common';
import aboutBanner from '../assets/AboutUs/banner.jpg';
import Lottie from "lottie-react";
import groovyWalkAnimation from "../assets/Animation/groovyWalk.json";
import ScrollAnimation from '../ScrollAnimation';
import { Helmet } from 'react-helmet';

const AboutUs = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const [section2, setSection2] = useState(null);
  const [section3, setSection3] = useState([]);
  const [apiCall1, setApiCall1] = useState(false);
  const [apiCall2, setApiCall2] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setApiCall1(true);
      try {
        const data = await getAboutSection2();
        setSection2(data);
        setApiCall1(false);
      } catch (error) {
        console.error(error);
        setApiCall1(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      setApiCall2(true);
      try {
        const data = await getAboutSection3();
        setSection3(data);
        setApiCall2(false);
      } catch (error) {
        console.error(error);
        setApiCall2(false);
      }
    };

    fetchData();
  }, []);
const mainLoading = apiCall1 || apiCall2;
  return (
    <>
    <Helmet>
        <title>About Us | Groupten Group of Companies - Leading Services in Kerala</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="Learn about Groupten Group of Companies, the leading provider of top-quality services in Kerala. Discover our commitment to excellence in restaurants, homestays, construction, and real estate. Join us in our journey of delivering exceptional experiences.
" />
        <meta property="og:title" content= "About Us | Groupten Group of Companies - Leading Services in Kerala"/>
        <meta property="og:description" content="Learn about Groupten Group of Companies, the leading provider of top-quality services in Kerala. Discover our commitment to excellence in restaurants, homestays, construction, and real estate. Join us in our journey of delivering exceptional experiences.
" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
    <div>
      {mainLoading ? 
      <Lottie animationData={groovyWalkAnimation} loop={true} className='h-screen'/>
        :
        <div>
      <div className="relative bg-cover bg-center h-96" style={{ backgroundImage: `url(${aboutBanner})` }}>
        <div className="absolute inset-0 bg-black opacity-20"></div>
       <Navbar />
        <div className="relative container mx-auto p-6 flex flex-col items-center justify-center h-full text-center text-white">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-semibold"></h1>
        </div>
      </div>

      {/* Content Section */}
      <ScrollAnimation className="container mx-auto p-6 my-12">
        <div className="text-center mb-20">
          <h2 className="text-3xl font-bold">{section2?.title}</h2>
        </div>
        <div className="flex flex-col md:flex-row md:space-x-6 items-start">
          {section2 ? 
          <img src={ImageUrl()+section2?.image} alt="Groupten Business" className="w-full md:w-1/3 h-auto rounded-lg shadow-lg md:mb-0" />
          :null}
          <div className="md:w-2/3">
            <p className="text-lg leading-relaxed">
              {section2?.description}
            </p>
          </div>
        </div>
      </ScrollAnimation>

      {/* Team Section */}
      {/* <ScrollAnimation className="bg-gray-100 py-12">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8">Meet Our Team</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
            {section3?.map((sec, key)=>{return(
            <div className="text-center" key={key}>
              <img src={ImageUrl()+sec.image} alt={sec.name} className="w-40 h-40 rounded-full mx-auto mb-4" />
              <h3 className="text-xl font-semibold">{sec.role}</h3>
              <p className="text-gray-600">{sec.name}</p>
            </div>
            )})}
          </div>
        </div>
      </ScrollAnimation> */}

      {/* <Footer /> */}
    </div>}</div>
    </>
  );
};

export default AboutUs;
