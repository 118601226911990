import React, { useEffect, useState } from 'react';
import { send_mail } from '../api/api';
import { IoIosCloseCircleOutline } from "react-icons/io";
const BookingModal = ({isOpen, setIsOpen, alert, setAlert, setApiCall4, apiCall4, selectBookingDetails}) => {
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    selected_room: selectBookingDetails?.room,
    price: selectBookingDetails?.price,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      selected_room: selectBookingDetails?.title,
      price: selectBookingDetails?.price,
    }));
  }, [selectBookingDetails])
  
  console.log(formData);
  const mailSubmit = async (e) => {
    e.preventDefault();
    setApiCall4(true);
    try {
      const response = await send_mail(formData);
      if(response.status == "success"){
        closeModal();
        setAlert({is_show: 1, type: "success", message: response.message})
      }
      else{
        closeModal();
        setAlert({is_show: 1, type: "error", message: "something went wrong"})
      }
      setApiCall4(false)
    } catch (error) {
      // closeModal();
      console.log(error.response.data);
      if(error.response.status == 422){
        let arrdata = error.response.data.errors;
        
        
          const firstErrorKey = Object.keys(arrdata)[0];
          const firstErrorMessage = arrdata[firstErrorKey][0];
        setAlert({is_show: 1, type: "error", message: firstErrorMessage})
      }
      else{
        setAlert({is_show: 1, type: "error", message: "something went wrong"})
      }
      setApiCall4(false)
    }
  };

  return (
    // <div className="bg-gray-100 flex items-center justify-center min-h-screen">
    //   <button 
    //     onClick={openModal} 
    //     className="bg-green-500 text-white font-bold py-2 px-4 rounded"
    //   >
    //     Book Now
    //   </button>
<div>
      {isOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-[1000]">
          <div className="bg-white p-6 rounded-lg shadow-lg md:w-1/2 w-full max-h-screen overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Hotel Booking Form</h2>
              <button 
                onClick={closeModal} 
                className="text-gray-500"
              >
                <IoIosCloseCircleOutline className='w-full h-8'/>
              </button>
            </div>
            <form className="overflow-y-auto max-h-[calc(100%-3rem)]">
              <div className="mb-2">
                <label htmlFor="name" className="block text-gray-700">Name</label>
                <input type="text" id="name" className="w-full p-1 border rounded" onChange={handleChange} name='name'/>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-2">
                <div>
                  <label htmlFor="email" className="block text-gray-700">Email</label>
                  <input type="email" id="email" className="w-full p-1 border rounded" onChange={handleChange} name='email'/>
                </div>
                <div>
                  <label htmlFor="phone" className="block text-gray-700">Phone</label>
                  <input type="text" id="phone" className="w-full p-1 border rounded" onChange={handleChange} name='phone'/>
                </div>
              </div>
              

              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-2">
                <div>
                  <label htmlFor="email" className="block text-gray-700">Selected Room</label>
                  <input type="email" id="selected_room" className="w-full p-1 border rounded" onChange={handleChange} name='selected_room' value={selectBookingDetails.title} disabled/>
                </div>
                <div>
                  <label htmlFor="phone" className="block text-gray-700">Price</label>
                  <input type="text" id="price" className="w-full p-1 border rounded" onChange={handleChange} name='price' value={selectBookingDetails.price} disabled/>
                </div>
              </div>



              {/* <div className="mb-2">
                <label htmlFor="address" className="block text-gray-700">Address</label>
                <input type="text" id="address" className="w-full p-1 border rounded" onChange={handleChange} name='address'/>
              </div> */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-2">
                <div>
                  <label htmlFor="city" className="block text-gray-700">City</label>
                  <input type="text" id="city" className="w-full p-1 border rounded" onChange={handleChange} name='city'/>
                </div>
                <div>
                  <label htmlFor="state" className="block text-gray-700">State</label>
                  <input type="text" id="state" className="w-full p-1 border rounded" onChange={handleChange} name='state'/>
                </div>
              </div>
              {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-2">
                <div>
                  <label htmlFor="zip" className="block text-gray-700">Zip Code</label>
                  <input type="text" id="zip" className="w-full p-1 border rounded" />
                </div>
                <div>
                  <label htmlFor="country" className="block text-gray-700">Country</label>
                  <input type="text" id="country" className="w-full p-1 border rounded" />
                </div>
              </div> */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-2">
                <div>
                  <label htmlFor="checkin" className="block text-gray-700">Check In</label>
                  <input type="date" id="checkin" className="w-full p-1 border rounded" onChange={handleChange} name='check_in'/>
                </div>
                <div>
                  <label htmlFor="checkout" className="block text-gray-700">Check Out</label>
                  <input type="date" id="checkout" className="w-full p-1 border rounded" onChange={handleChange} name='check_out'/>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-2">
                <div>
                  <label htmlFor="noofperson" className="block text-gray-700">No Of Person</label>
                  <input type="number" id="noofperson" className="w-full p-1 border rounded" onChange={handleChange} name='no_of_person'/>
                </div>
                {/* <div>
                  <label htmlFor="gender" className="block text-gray-700">Gender</label>
                  <select id="gender" className="w-full p-1 border rounded">
                    <option>Male</option>
                    <option>Female</option>
                  </select>
                </div> */}
                <div>
                  <label htmlFor="kids" className="block text-gray-700">Kids</label>
                  <input type="number" id="kids" className="w-full p-1 border rounded" onChange={handleChange} name='kids'/>
                </div>
              </div>
              <div className='flex md:gap-10 gap-2 flex-col md:flex-row'>
                {!apiCall4 ? 
                <button type="submit" className="bg-green-500 text-white font-bold py-2 px-4 rounded w-full" onClick={mailSubmit}>Submit</button> : <button  className="bg-green-500 text-white font-bold py-2 px-4 rounded w-full disabled:cursor-not-allowed" disabled={true} onClick={mailSubmit}>loading</button>}
                <button type="submit" className="bg-red-500 text-white font-bold py-2 px-4 rounded w-full" onClick={closeModal} >Cancel</button>
              </div>
              
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingModal;
