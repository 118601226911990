import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Business from './components/Business';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import Gallery from './components/Gallery';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs.jsx';
import ContactUs from './pages/ContactUs.jsx';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import DmPage from './pages/DmPage.jsx';
import { getCompanyList, getSeo } from './api/api.js';



const App = () => {
  const [companyLists, setCompanyLists] = useState([])
  const [apiCall2, setApiCall2] = useState(false)
  const [seo, setSeo] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const seoData = await getSeo();
        setSeo(seoData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

useEffect(() => {
  const fetchData = async () => {
    setApiCall2(true);
    try {
      const companyLists = await getCompanyList();
      setCompanyLists(companyLists);
      setApiCall2(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setApiCall2(false);
    }
  };

  fetchData();
}, []);


var fullUrl = window.location.href;
var url = new URL(fullUrl);
var pathname = url.pathname;
var pageName = pathname.split('/').filter(Boolean).pop();
  return (
    
    <div className=''>
      <BrowserRouter>
        {/* <Header /> */}
        <Routes>
          <Route path="/" element={<Home companyLists={companyLists} apiCall2={apiCall2} seo={seo}/>} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/dm/:name" element={<DmPage companyLists={companyLists}/>} />
        </Routes>
        <Footer pageName={pageName}/>
      </BrowserRouter>
    </div>
  );
};

export default App;
